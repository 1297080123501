.btttnn {
  background-image: linear-gradient(to right, #609f08 0%, #a3c571 51%, #a1de4c) !important;
  padding: 7px 25px !important;
  border-radius: 5px !important;
}

.logMargn {
  margin-right: 10px !important;
  color: white !important;
}

.navMainn {
  background: white !important;
  color: white !important;
  font-weight: bold;
}

.navbar-expand-lg .navbar-nav .nav-link {
  color: black !important;
  margin-right: 20px !important;
}

.navbar-expand-lg .navbar-nav .nav-link:hover {
  color: rgba(221, 207, 10, 0.9) !important;
  border-bottom: 1px solid rgba(221, 207, 10, 0.9);
}

.navbar {
  padding: 0.5rem 0 !important;
}

.navbar-toggler {
  background-image: linear-gradient(to right, #609f08 0%, #a3c571 51%, #a1de4c) !important;
}

.resFlx a{
    color: black !important;
    font-weight: bold;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 …it='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
  display: inline-block !important;
  width: 1.5em !important;
  height: 1.5em !important;
  vertical-align: middle !important;
  content: "" !important;
  background: no-repeat center center !important;
  background-size: 100% 100% !important;
}

@media screen and (max-width: 950px) {
  .navbar {
    padding: 0.5rem 1rem !important;
  }

  .resFlx {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important ;
    align-items: center !important;
    text-align: center !important;
  }

  .navbar-nav {
    margin: 0 !important;
  }

  .logMargn {
    margin-bottom: 10px;
  }
}
