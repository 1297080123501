body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.padtttt {
  padding-top: 100px;
}

@media screen and (max-width: 650px) {
  .dataTables_wrapper {
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .content-page_See {
    padding-top: 150px;
  }

  .dt-buttons {
    margin-bottom: 10px;
  }
}

/* vision & mision section start */
.visionmissionsection .btnheading {
  border: 1px solid rgba(5, 5, 5, .14);
  border-radius: 30px;
  background-color: transparent;
  padding: 6px 30px;
}

.visionmissionsection .btnheading span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: black;
  font-size: 15px;
  text-transform: capitalize;
  position: relative;

}

.visionmissionsection .btnheading span::after {
  position: absolute;
  content: '';
  left: -15px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: black;
}

.visionmissionsection .sectionheading {
  font-size: 48px;
  font-weight: 800;
  color: #050505;
  line-height: 60px;
}

.visionmissionsection .servicecard {
  border-color: rgba(5, 5, 5, .14);
  border-radius: 14px;
  transition: all 0.3s ease-in-out;
}

.visionmissionsection .servicecard:hover {
  border-color: #65ab03;
}

.visionmissionsection .card-icon {
  background-image: linear-gradient(132deg, rgba(101, 171, 3, 0.2) 11.08%, rgba(101, 171, 3, 0) 90.05%);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.visionmissionsection .headingcard a {
  font-size: 22px;
  margin-bottom: 7px;
  font-weight: 800;
  color: #050505;
  transition: all 0.3s ease-in-out;
}

.visionmissionsection .headingcard a:hover {
  color: #65ab03;
}

.visionmissionsection p {
  font-size: 14px;
}

/* vision & mision section End */


/* Service Section start */
.servicesection {
  position: relative;
  background-color: #f7f7f7;
}

.servicesection .btnheading {
  border: 1px solid rgba(5, 5, 5, .14);
  background-color: transparent;
  padding: 6px 30px;
  border-radius: 0px;
}

.servicesection .btnheading span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: black;
  font-size: 15px;
  text-transform: uppercase;
  position: relative;

}

.servicesection .btnheading span::after {
  position: absolute;
  content: '';
  left: -15px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: black;
}

.servicesection .sectionheading {
  font-size: 60px;
  font-weight: bold;
  color: #050505;
  line-height: 68px;
}

.servicesection .servicecard {
  transition: all 0.3s ease-in-out;

}

.servicesection .servicecard .card-image {
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.servicesection .servicecard .card-image img {
  transition: all .4s ease;
  -o-object-fit: cover;
  object-fit: cover;
}

.servicesection .servicecard:hover .card-image img {
  transform: scale(1.1);
}

.servicesection .servicecard .card-body {
  background: #fff;
  transition: all .4s ease;
  padding: 24px 40px 40px;
  position: relative;
  margin: 0 25px;
  margin-top: -80px;
  overflow: hidden;
}

.servicesection .servicecard .card-body .card-icon {
  height: 54px;
  width: 54px;
}


.servicesection .servicecard .card-body .card-heading {
  font-size: 22px;
  font-weight: 700;
  color: #142137;
  margin: 20px 0 20px 0;
  transition: all .4s ease;
}

.servicesection .servicecard .card-body .card-heading:hover {
  color: #65ab03;
}

.servicesection .servicecard .card-body ul {}

.servicesection .servicecard .card-body ul li {
  color: rgba(20, 33, 55, .7);
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.servicesection .servicecard .card-body ul li span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}

.servicesection .servicecard .card-body .redmore {
  color: #2d6a4f;
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.servicesection .servicecard .card-body .redmore span {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.servicesection .hiddenpart {
  opacity: 0;
  height: 0;
  transition: all 0.3s ease-in-out;
}

.servicesection:hover .hiddenpart {
  height: 100%;
  opacity: 1;
}

.hiddenpart {
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease;
  /* Smooth transition */
}

.servicecard:hover .hiddenpart {
  display: block;
  opacity: 1;
}

.servicecard.active .hiddenpart {
  display: block;
  opacity: 1;
}

.servicesection {
  position: relative;
}

.servicesection .image1 {
  position: absolute;
  top: 200px;
  height: 300px;
  width: 200px;
  z-index: -1;
}

.servicesection .image2 {
  position: absolute;
  bottom: 50px;
  right: 0;
  height: 300px;
  width: 200px;
  z-index: -1;
}

/* service page End */

/* footer Start */
.footermain {}

.footermain .logofooter {
  width: 188px;
  height: 90px;
  display: flex;
  align-items: center;
}

.footermain .footericon_group {
  display: flex;
  align-items: center;
  gap: 1.4rem;
}

.footermain .icons {
  width: 54px;
  height: 54px;
  line-height: 54px;
  text-align: center;
  border: 1px solid rgba(2, 2, 8, .14);
  border-radius: 50%;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
}

.footermain .icons img {
  padding: 15px;
}

.footermain .footertopsection {
  display: flex;
  flex-direction: column;

}

.footermain .footertopsection .footerheadingtop {
  font-size: 17px;
}

.footermain .footertopsection .numbertext {
  font-size: 15px;
  line-height: 20px;
}

.footermain .footerheading {
  font-size: 20px;
  font-weight: 700;
  color: white;
  margin-bottom: 25px;
}

.footermain .subtext {
  font-weight: 400;
  font-size: 15px;
  color: rgba(255, 255, 255, .6);
  margin-bottom: 27px;

}

.footermain .iconlistsocial {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.footermain .iconlistsocial li {}

.footermain .iconlistsocial li a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid rgba(255, 255, 255, .1);
  text-align: center;
  border-radius: 50%;
  color: rgba(255, 255, 255, .5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px;

}

.footermain .iconlistsocial li a i {
  font-size: 25px;
}

.footermain .imagetextgroup {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.footermain .imagetextgroup .imagefooter {
  width: 180px;
  height: 90px;
}

.footermain .imagetextgroup .datetext {
  font-size: 14px;
  line-height: 19px;
}

.footermain .imagetextgroup .querytext {
  font-size: 17px;
  line-height: 25px;
}

.footermain .nwsltr-top input {
  border: 1px solid rgba(255, 255, 255, .16);
  border-right: 0;
  padding: 10px 22px;
  height: 50px;
}

.footermain .nwsltr-top button {
  background-color: #2d6a4f;
  width: 50px;
  height: 50px;
  border-radius: 0px;
  position: absolute;
  padding: 10px;
}

.footermain .nwsltr-top button span {
  width: 20px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* footer end */
.banners .d-none {
  display: none !important;
}

/* banner start */
.section_banner {
  height: 100vh;
}

.bannerimage1 {
  background-image: url(./Assets/WebImages/bg1.jpeg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
}

.bannerimage1 .banners {
  height: 100vh;
}

.bannerimage1 .banners .headingbanner {
  color: #fff;
  font-size: 75px;
  font-weight: 800;
  margin-bottom: 60px;
}

.bannerimage1 .banners .herobtn {
  background: #fff;
  color: #081c15;
  font-size: 16px;
  font-weight: 700;
  width: 200px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all .4s ease;
  z-index: 1;
}

.bannerimage1 .banners .playbtn {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.bannerimage1 .banners .playbtn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.listst li {
  list-style: disc !important;
}

.bannerimage2 {
  background-image: url(./Assets/WebImages/bg.jpeg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
}

.bannerimage2 .banners {
  height: 100vh;
}

.bannerimage2 .banners .headingbanner {
  color: #fff;
  font-size: 75px;
  font-weight: 800;
  margin-bottom: 60px;
}

.bannerimage2 .banners .herobtn {
  background: #fff;
  color: #081c15;
  font-size: 16px;
  font-weight: 700;
  width: 200px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all .4s ease;
  z-index: 1;
}

.bannerimage2 .banners .playbtn {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.bannerimage2 .banners .playbtn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

/* 3 */
.bannerimage3 {
  background-image: url(./Assets/WebImages/bg2.jpeg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
}

.bannerimage3 .banners {
  height: 100vh;
}

.bannerimage3 .banners .headingbanner {
  color: #fff;
  font-size: 75px;
  font-weight: 800;
  margin-bottom: 60px;
}

.bannerimage3 .banners .herobtn {
  background: #fff;
  color: #081c15;
  font-size: 16px;
  font-weight: 700;
  width: 200px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all .4s ease;
  z-index: 1;
}

.bannerimage3 .banners .playbtn {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.bannerimage3 .banners .playbtn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

/* Your CSS rules for screens between 320px and 575.99px */
@media only screen and (min-width: 320px) and (max-width: 575.99px) {
  .bannerimage2 .banners .headingbanner {
    font-size: 40px;
    text-align: center;
  }

  .bannerimage3 .banners .headingbanner {
    font-size: 40px;
    text-align: center;
  }

  .bannerimage1 .banners .headingbanner {
    font-size: 40px;
    text-align: center;
  }

  .bannerimage2 {
    background-size: unset;
  }

  .bannerimage3 {
    background-size: unset;
  }

  .bannerimage1 {
    background-size: unset;
  }

  .flexcolumn {
    /* flex-direction: column; */
    align-items: start !important;
    justify-content: start;
  }

  .bannerimage1 .banners .herobtn {
    width: 150px;
  }

  .bannerimage2 .banners .herobtn {
    width: 150px;
  }

  .bannerimage2 .banners .playbtn {
    width: 50px;
    height: 50px;
  }

  .bannerimage3 .banners .herobtn {
    width: 150px;
  }

  .bannerimage3 .banners .playbtn {
    width: 50px;
    height: 50px;
  }

  .title_purple_dark h4 {
    font-size: 30px;
    text-align: center;
  }

  .visionmissionsection {
    padding-top: 150px;
  }

  .visionmissionsection .sectionheading {
    font-size: 30px;
    line-height: 40px;
  }

  .servicesection .sectionheading {
    font-size: 30px;
    line-height: 40px;
  }

  .servicesection .servicecard .card-body {
    padding: 20px 22px 22px;
  }

  .footermain .logofooter {
    margin: auto;
  }

  .footermain .imagetextgroup .querytext {
    font-size: 14px;
  }

  .footermain .imagetextgroup .imagefooter {
    width: 150px;
    height: 70px;
  }

  .section_banner {
    overflow: hidden;
  }
}

/* Contact Page start */
.contactsection .headingpoint {
  background-color: #f1ffed;
}

.contactsection .phoneicon {
  width: 30px;
  height: 30px;
}

.contactsection .rv-5-footer-timings {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.contactsection .rv-5-footer-timings li {
  color: black;
  font-size: 14px;
  font-weight: 400;
  gap: 6px 15px;
  flex-wrap: wrap;
  line-height: normal;
  display: flex;
  justify-content: space-between;
}

.contactsection input {
  border-color: #dedede;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, .14);
  padding: 9px 20px;
  background-color: rgba(0, 0, 0, 0);
  height: 47px;
  border-radius: 8px;
}

.contactsection select {
  height: 47px;
  border-radius: 8px;
  padding: 10px 15px;
  border-color: #dedede;
}

.contactsection textarea {
  border-color: #dedede;
}

.contactsection button {
  background-color: #65ab03;
  color: white;
  border: 1px solid #65ab03;
  transition: all 0.3s ease-in-out;
  font-weight: bold;
}

.contactsection button:hover {
  background-color: white;
  color: black;
}

/* other section */
.sectionimagecontent1 .mainimage {
  height:500px;
}
.sectionimagecontent1 .mainimage img{
  border-radius: 10px 10px 0px 0px ;
}
@media (min-width: 320px) and (max-width: 576px) {
  .sectionimagecontent1 .mainimage {
    height: 300px;
  }
}
.sectionimagecontent .listiner {}

.sectionimagecontent .listiner li {
  font-size: 14px;
  color: black;
}
.sectionimagecontent h4{
  margin-top: 15px;
  color: #2d6a4f !important;
}
.sectionimagecontent p {
  font-size: 14px;
  color: black;
}