
.content-page {
    overflow: hidden;
    /* margin-left: 260px; */
    padding-top: 10px;
    /* min-height: 100vh; */
    background: #f6f6f6;
    /* -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out; */
    transition: all 0.3s ease-in-out;
}

.content-page_See {
    overflow: hidden;
    /* margin-left: 260px; */
    padding-top: 200px;
    /* min-height: 100vh; */
    background: #f6f6f6;
    /* -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out; */
    transition: all 0.3s ease-in-out;
}


.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    /* background-image: linear-gradient(to bottom right, #00eebb, #7cef01); */
    border-radius: 10px;
    margin-bottom: 30px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,.1), 0 0.9375rem 1.40625rem rgba(90,97,105,.1), 0 0.25rem 0.53125rem rgba(90,97,105,.12), 0 0.125rem 0.1875rem rgba(90,97,105,.1);
    
}

.card .card-header.py_10 {
    padding: 16px 20px 14px;
}

.card .card-header {
    background: transparent;
    border-radius: 0;
    /* padding: 20px;
    margin-bottom: 0; */
    -ms-flex-align: center !important;
    /* align-items: center !important; */
    /* border-bottom: 1px solid #ffa117; */
    border-bottom: 1px solid rgb(194, 16, 16);

}

.card .card-header .header-title .card-title {
    margin-bottom: 0;
}

 /* .card-title h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    margin: 0px;
    line-height: 1;
    color: #545252;
    font-size: 1.2em;
} */

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    /* padding: 1.25rem; */
}
.profile-widget-header{
    padding: 10px;
}

@media screen and (max-width:550px) {
    .hxncardc{
        width: max-content !important;
    }

    .reText{
        font-size: 20px;
    }
    .refferal_item .profile-widget .profile-widget-items {
         display: block !important; 
        /* position: relative; */
        border-bottom: 1px solid #eee;
    }

    .refferal_item .profile-widget .profile-widget-picture {
        box-shadow: 0 4px 25px 0 rgba(0,0,0,.1);
        float: unset !important;
        width: 100px;
        margin: -41px 0px 0 0px !important;
        position: relative;
        z-index: 1;

    }
}

@media screen and (max-width:950px) {
    .hxnaddBtn{
        margin-top: 10px;
        margin-bottom: 30px;
    }
}

@media (min-width: 951px) and (max-width: 10000px) {
    .hxnuwyubbnnrs{
        height: 950px !important;
    }
}

/* .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
} */

.dt-buttons button {
    border-radius: 4px !important;
    margin: 0 6px;
    padding: 1px 12px;
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    height: auto;
}

.dt-buttons button:nth-child(1) {
    background-color: #2ab9d0;
}

.dt-buttons button:nth-child(2) {
    background-color: #59bf70;
}

.dt-buttons button:nth-child(3) {
    background-color: #e91e63;
}

.dataTables_filter {
    float: right;
    /* text-align: right; */
}


.dataTables_paginate {
    float: right;
    text-align: right;
    padding-top: 0.25em;
}

.pagination_tb {
    display: flex;
    margin: 0;
}

.pagination_tb li a {
    padding: 4px 11px;
    border: 1px solid #d7d3d3;
    border-radius: 4px;
    font-size: 14px;
}

a {
    color: #8d8d8d;
    text-decoration: none;
    background-color: transparent;
}

.pagination_tb li a.active {
    background-color: #d9202a;
    color: #fff;
    border-color: #d9202a;
}

.refferal_item .profile-widget {
    margin-top: 35px;
}

.refferal_item .profile-widget .profile-widget-header {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
}

.refferal_item .profile-widget .profile-widget-picture {
    box-shadow: 0 4px 25px 0 rgba(0,0,0,.1);
    float: left;
    width: 100px;
    margin: -35px -5px 0 30px;
    position: relative;
    z-index: 1;
}

.refferal_item .profile-widget .profile-widget-items {
    display: flex;
    position: relative;
    border-bottom: 1px solid #eee;
}

.refferal_item .profile-widget .profile-widget-items .profile-widget-item {
    flex: 1;
    text-align: center;
    padding: 10px 0;
}