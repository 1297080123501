.tablebtn {
  border-radius: 0 20px;
  margin: 0 5px;
  color: #fff;
  border-color: var(--primary-bg-color);
  /* background: #0b241d; */
  background: linear-gradient(90deg, #0a8830 0%, #46320e 100%);
  font-weight: 700;
}

.bg-pp {
  /* background: #0b241d; */
  background: linear-gradient(90deg, #0a8830 0%, #46320e 100%);
  border-radius: 7px;
  color: #fff;
  font-weight: 600;
}

.Table_Buttons {
  overflow-x: scroll;
}

.px-6 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

@media screen and (max-width: 650px) {
  .Table_Buttons {
    margin-left: 3px !important;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media (min-width: 651px) and (max-width: 10000px) {
  .Table_Buttons {
    margin-left: 3px !important;
    margin-top: 5px;
    margin-bottom: 5px;
    justify-content: center;
  }
}

@media only screen and (max-width: 1000px) {
  .table_tr {
    line-height: 1.5 !important;
  }

  td {
    padding: 0.5rem 0.2rem;
    color: black;
    font-size: 1.2rem;
    text-align: center;
  }
}
