.mainnew {
  /*width: 60%;*/
  /* margin: 30px 20%; */
  background-color: #cfffe6;
  padding: 3%;
  float: left;
  border: solid 2px #fedc00;
  color: #fff;
  border-radius: 3px;
}
.mainwelc {
  /*width: 60%;*/
  /* margin: 30px 20%; */
  background-color: #00d4ad;
  padding: 3%;
  float: left;
  border: solid 2px #fedc00;
  color: #fff;
  border-radius: 3px;
}

@media print {
  .no-print {
    display: none;
  }
  .wrapper {
    background-color: #fff;
    color: #000;
  }
  .text-white {
    color: #000;
  }
}

.doclogo {
  height: 10rem;
  width: 54%;
}

.footeragr{
  border-top: 2px solid red;
}


@media (min-width: 320px) and (max-width: 576px) {
  .doclogo {
    width: 50%;
  }
}
