.offcanvasBtn {
  background-color: transparent !important;
  border: none !important;
}

.fade.show {
  opacity: 0.5 !important;
}

.modal.fade.show {
  opacity: 1 !important;
}

.btn-primary:focus {
  box-shadow: none !important;
}

.mainnav .navmenu ul {
  margin: 0px 0px;
  list-style-type: none;
  padding: 0px 0px;
}

.modal-body img {
  width: 70px;
}

.mainnav .navmenu > ul > li a {
  font-size: 18px;
  text-decoration: none;
  display: block;
  padding: 15px 20px;
  color: var(--color-black);
}

.offcanvas {
  width: 300px !important;
}

.offcanvas ul li {
  color: #1b2430 !important;
  list-style: none !important;
  font-size: 18px !important;
}

.offcanvas ul {
  padding-left: 0.5rem !important;
}

.accordion {
  --bs-accordion-bg: transparent !important;
  --bs-accordion-btn-focus-border-color: transparent !important;
  --bs-accordion-btn-border: none !important;
  --bs-accordion-active-bg: transparent !important;
  --bs-accordion-btn-padding-x: 0 !important;
  --bs-accordion-btn-padding-y: 0.5rem !important;
  border: none !important;
}

.accordion h2 {
  padding: 0 !important;
  border-bottom: none !important;
}

.accordion-button {
  color: #1b2430 !important;
}

.accordion-button:not(.collapsed) {
  color: #1b2430 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

@media (min-width: 600px) {
  .main_logo_canvas {
    width: 69%;
    margin-top: 2px;
  }
}

.offcanvas-body {
  font-family: "Rajdhani", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
}

.accordion-item {
  color: #1b2430 !important;
  background-color: transparent !important;
  border: none !important;
}

.offcanvas ul li img {
  height: 28px;
  margin-right: 5px;
}

.offcanvas ul li a {
  text-decoration: none !important;
  color: #1b2430;
}

.accordion-button::after {
  display: none !important;
}

.accordion-body ul li {
  padding-bottom: 20px !important;
  font-size: 18px !important;
}

.accordion-body {
  padding: 2px 15px !important;
  background-color: #f7f5f2 !important;
}

.header-icon {
  text-align: center;
  line-height: 1.8;
  font-size: 1.3rem;
  color: #1b2430 !important;
  -webkit-filter: drop-shadow(0px 2px 5px #1b2430);
  filter: drop-shadow(0 2px 5px #1b2430);
}

.fe-align-left:before {
  content: "\e907" !important;
}

.fe {
  font-family: "feather" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1.1;
  font-size: initial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.offCanvasButton {
  background-color: transparent !important;
  border: none !important;
}

.accordion-button {
  font-size: 18px !important;
  margin-bottom: 15px !important;
}

.Dashboardaksmcl {
  padding-bottom: 15px;
}

.side-menu__icon {
  width: 20px;
  margin-right: 10px;
}

@media screen and (max-width: 550px) {
  .main_logo,
  .main_logo_canvas {
    width: 20%;
  }
}
