.wrapper_main {
            width: 100%;
            float: left;
            height: auto;
        }

        .wrapper {
            /* width: 900px; */
            margin: 0px auto;
            font-family: 'Roboto', sans-serif;
        }

            .wrapper h6 {
                background-color: #cacaca;
                font-size: 13px;
                font-weight: 300;
                width: 90%;
                line-height: 19px;
                float: left !important;
                margin: 0px;
                text-align: justify;
                padding: 5%;
            }

        .header {
            width: 95%;
            float: left;
            background-color: #0088c6;
            padding: 22px;
            font-family: 'Roboto', sans-serif;
        }

        .logo {
            width: 40%;
            float: left;
            padding-left: 68px;
        }

        .number {
            width: 34%;
            float: right;
            font-size: 14px;
            text-align: -webkit-right;
            color: #fff;
            line-height: 25px;
            margin-right: 72px;
        }

        .main {
            /*width: 60%;*/
            margin: 30px 20%;
            background-color: #221d3f;
            padding: 3%;
            float: left;
            border: solid 2px #fedc00;
            color: #fff;
            border-radius: 3px;
        }

            .main h1 {
                width: 100%;
            }

        .main1 {
            width: 100%;
            float: left;
            /* background-color: #004a7c; */
        }

        .main p {
            line-height: 30px;
        }

       

        .but {
            width: 100%;
            margin-top: 20px;
        }

            .but a {
                padding: 14px 20px;
                color: #fff;
                text-decoration: none;
                font-weight: 600;
                border-radius: 5px;
                background-color: #f11f09;
            }

        .photo {
            width: 100%;
            float: left;
            margin-top: 30px;
        }

            .photo h5 {
                width: 50%;
                float: left;
            }

                .photo h5 img {
                    width: 100%;
                    float: left;
                }

            .photo p {
                width: 48%;
                float: right;
                padding-top: 30px;
                font-size: 15px;
            }

            .photo h1 {
                padding: 10px 22px;
                float: left;
                border: solid 4px #02c54c;
                width: initial;
                font-size: 16px;
                margin-left: 14px;
            }

        @media screen and (min-width:320px) and (max-width:700px) {
            .wrapper {
                width: 100%;
            }

            .logo {
                width: 38%;
                padding-left: 0px;
            }

            .number {
                width: 60%;
                float: right;
                font-size: 13px;
                margin-right: 0px;
            }

            .main {
                width: 90%;
                margin: 30px 3%;
                padding: 2%;
            }
        }