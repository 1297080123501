/*charset="utf-8"*/

tr:nth-child(odd) {
  border-bottom: 1px solid;
  padding: 10px;
  line-height: 25px;
}

tr.fgtybmd td {
  font-size: 14px;
}

.dropdown button.dropbtn input {
  border-radius: 50px;
}

.dropdown button.dropbtn img {
  border-radius: 50px;
}
.abc {
  width: 55px;
}
.market-updates {
  height: 728px;
}

.tables {
  /* background-color: rgba(0, 0, 0, 0.79); */
  color: white;
  width: 385px;
  border: 11px solid rgba(0, 0, 0, 0);
  border-radius: 9px;
}

.table_heading {
  text-align: left;
  font-size: 12px;
  color: white;
  text-shadow: 0px 0px 1px rgb(90, 77, 77);
}

.red img {
  border: 1px solid red;
}

/*.green img {
        border: 1px solid green;
    }*/
.treeview {
  float: none;
  padding: 0px;
  margin: 0px;
}

.content-wrapper {
  /*height:;*/
}

.treemember {
  margin: 0px auto;
  text-align: center;
}

.red img {
  border: 1px solid red;
}

.tree_row {
  width: 903px;
  height: 60px;
  margin: 0px auto;
}
.tree_row1 {
  width: 906px;
  height: 60px;
  margin: 0px auto;
}
.tree_row8 {
  width: 880px;
  height: 60px;
  margin: 0px auto;
}
.tree_row2 {
  width: 910px;
  height: 60px;
  margin: 0px auto;
}
.tree_row3 {
  width: 910px;
  height: 60px;
  margin: 0px auto;
}
.row_5_child {
  width: 301px;
  height: 60px;
  position: relative;
  text-align: center;
  float: left;
}
.row_6_child {
  width: 301px;
  height: 60px;
  position: relative;
  text-align: center;
  float: left;
}
.row_2_child {
  width: 301px;
  height: 60px;
  position: relative;
  text-align: center;
  float: left;
}
.row_8_child {
  width: 440px;
  height: 60px;
  position: relative;
  text-align: center;
  float: left;
}
.row_9_child {
  width: 440px;
  height: 60px;
  position: relative;
  text-align: center;
  float: left;
}
.tree_row9 {
  width: 880px;
  height: 60px;
  margin: 0px auto;
}
.row_10_child {
  width: 220px;
  height: 60px;
  position: relative;
  text-align: center;
  float: left;
}
.tree_row10 {
  width: 880px;
  height: 60px;
  margin: 0px auto;
}
.row_11_child {
  width: 220px;
  height: 60px;
  position: relative;
  text-align: center;
  float: left;
}
.row_12_child {
  width: 110px;
  height: 60px;
  position: relative;
  text-align: center;
  float: left;
}
.tree_row12 {
  width: 880px;
  height: 60px;
  margin: 0px auto;
}
.tree_row11 {
  width: 880px;
  height: 60px;
  margin: 0px auto;
}

.row_3_child {
  width: 101px;
  height: 60px;
  position: relative;
  text-align: center;
  float: left;
}

.row_4_child {
  width: 110px;
  height: 86px;
  position: relative;
  text-align: center;
  float: left;
}

.row_4_child {
  width: 110px;
  height: 60px;
  position: relative;
  text-align: center;
  float: left;
}

.span {
  font-family: Verdana;
  font-size: 9pt;
  text-align: center;
  color: #000;
}
.connecter1 {
  text-align: center;
}

.con {
  width: 880px;
  height: 35px;
  float: none;
  padding: 0px;
  text-align: center;
  margin: 0px auto;
}
.treeviewpage .card:hover {
  box-shadow: inset 0px -1px 12px 3px #9e0e0e !important;
}
.treeviewpage .card .tree_icon {
  display: flex;
  justify-content: space-evenly;
  height: 100%;
  /* align-items: center; */
}
.treeviewpage .card .tree_icon .tree_name {
  color: #ffb200;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 15px;
}
.treeviewpage .card {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgb(190 17 19);
  transition: all 0.3s ease-in-out;
  height: 100%;
  margin-bottom: 0;
}
.tables {
  /* background: linear-gradient(90deg, #8fd41f 0%, #ffe569 100%)  !important; */
  background: linear-gradient(90deg, #bb1114 0%, #5861c1 100%) !important;
  color: #000;
  border: 11px solid rgba(0, 0, 0, 0);
  border-radius: 9px;
}

.table_heading {
  color: #000;
  text-shadow: 0px 0px 1px rgb(90, 77, 77);
}

.topmargin {
  margin-top: 10px;
  padding: 5px 10px;
  margin-left: 5px;
}

.dropbtn {
  background: none;
  color: #000;
  padding: 0px 16px 0px 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.dropbtn:hover {
  background: none !important;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  z-index: 4;
  margin-left: -248px;
}
@media screen and (min-width: 320px) and (max-width: 575px) {
  .dropdown-content {
    margin-left: 0x;
  }
  .dropdown-content table.tables {
    margin-left: 248px !important;
  }
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/*.dropdown:hover .dropbtn {
        background-color: #fff;
    }*/
.dropdown-content table.tables {
  margin-left: 154px;
}

.grtydfbc {
  border-right: 1px solid #000;
  font-size: 12px;
  color: #000;
}

.fgtybmd {
  border-bottom: 1px solid #000;
  padding-bottom: 2px;
}

.content-page {
  margin-left: 240px;
  overflow: scroll;
}

.card {
  background-color: transparent;
  box-shadow: unset;
  border: unset;
}
.btnss{
  width: 50%;
  margin: 0px 10px;
}

.srch_frm {
  margin-top: 0px;
  color: #000;
  border: solid 1px #fff;
  background-color: #fff;
  width:100%;
  float: left;
}

@media screen and (min-width: 320px) and (max-width: 800px) {
  .card {
    /*float: left;
            width: 1360px;*/
    overflow: scroll;
  }

  content-page {
    margin-left: 240px;
    overflow: scroll !important;
  }

  .content-page {
    margin-left: 0px;
    overflow: scroll;
  }
}

.float_rt {
  float: right;
}

@media (max-width: 768px) {
  .form-wrapper {
    text-align: left;
  }

  .srch_frm {
    width: 100%;
  }

  .float_rt {
    float: inherit;
  }
}
/* .treeviewpage{
    padding-top: 100px;
} */
.treeviewpage .card .form-wrapper {
  display: flex;
  align-items: center;

  justify-content: space-between;
}

@media (max-width: 575.98px) {
  .treeviewpage .card .form-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 0.5rem;
  }
}
/* .tree_icon{
    display: flex;
    justify-content: space-evenly;
    background: #247b0e;
    margin: 30px;
    padding: 13px;
    border-radius: 6px;
    font-size: 18px;
} */
/* .tree_name{
    color: white;
} */
