.tree_view {
	font-size: 16px;
	overflow: auto;
}
.tree_view * {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
.tree_view button{
    min-width:155px;
    height: auto;
}
.tree_view ul {
	display: inline-flex;
}
.tree_view li {
	align-items: center;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	padding: 0 1em;
	position: relative;
}
.tree_view li ul {
	margin: 2em 0;
}
.tree_view li li:before {
	border-top: 0.0625em solid #000;
	content: "";
	display: block;
	height: 0.0625em;
	left: -0.03125em;
	position: absolute;
	top: -1.03125em;
	width: 100%;
}
.tree_view li li:first-child:before {
	left: calc(50% - 0.03125em);
	max-width: calc(50% + 0.0625em);
}
.tree_view li li:last-child:before {
	left: auto;
	max-width: calc(50% + 0.0625em);
	right: calc(50% - 0.03125em);
}
.tree_view li li:only-child:before {
	display: none;
}
.tree_view li li:only-child > .tree_view_parent:before,
.tree_view li li:only-child > .tf-node-content:before {
	height: 1.0625em;
	top: -1.0625em;
}
.tree_view .tree_view_parent,
.tree_view .tf-node-content {
	 /* border: 0.0625em solid black;  */
	display: inline-block;
	padding: 5px 5px;
	position: relative;
	text-align: center;
}
.tree_view .tree_view_parent:before,
.tree_view .tf-node-content:before {
	top: -1.03125em;
}


.tree_view .tree_view_parent:after,
.tree_view .tree_view_parent:before,
.tree_view .tf-node-content:after,
.tree_view .tf-node-content:before {
	border-left: 0.0625em solid #000;
	content: "";
	display: block;
	height: 1em;
	left: calc(50% - 0.03125em);
	position: absolute;
	width: 0.0625em;
}
.tree_view .tree_view_parent:after,
.tree_view .tf-node-content:after {
	top: calc(100% + 0.03125em);
}
.tree_view .tree_view_parent:only-child:after,
.tree_view .tf-node-content:only-child:after,
.tree_view > ul > li > .tree_view_parent:before,
.tree_view > ul > li > .tf-node-content:before {
	display: none;
}
.tree_view.tf-gap-sm li {
	padding: 0 0.6em;
}
.tree_view.tf-gap-sm li > .tree_view_parent:before,
.tree_view.tf-gap-sm li > .tf-node-content:before {
	height: 0.6em;
	top: -0.6em;
}
.tree_view.tf-gap-sm li > .tree_view_parent:after,
.tree_view.tf-gap-sm li > .tf-node-content:after {
	height: 0.6em;
}
.tree_view.tf-gap-sm li ul {
	margin: 1.2em 0;
}
.tree_view.tf-gap-sm li li:before {
	top: -0.63125em;
}
.tree_view.tf-gap-sm li li:only-child > .tree_view_parent:before,
.tree_view.tf-gap-sm li li:only-child > .tf-node-content:before {
	height: 0.6625em;
	top: -0.6625em;
}
.tree_view.tf-gap-lg li {
	padding: 0 1.5em;
}
.tree_view.tf-gap-lg li > .tree_view_parent:before,
.tree_view.tf-gap-lg li > .tf-node-content:before {
	height: 1.5em;
	top: -1.5em;
}
.tree_view.tf-gap-lg li > .tree_view_parent:after,
.tree_view.tf-gap-lg li > .tf-node-content:after {
	height: 1.5em;
}
.tree_view.tf-gap-lg li ul {
	margin: 3em 0;
}
.tree_view.tf-gap-lg li li:before {
	top: -1.53125em;
}
.tree_view.tf-gap-lg li li:only-child > .tree_view_parent:before,
.tree_view.tf-gap-lg li li:only-child > .tf-node-content:before {
	height: 1.5625em;
	top: -1.5625em;
}
.tree_view li.tf-dotted-children .tree_view_parent:after,
.tree_view li.tf-dotted-children .tree_view_parent:before,
.tree_view li.tf-dotted-children .tf-node-content:after,
.tree_view li.tf-dotted-children .tf-node-content:before {
	border-left-style: dotted;
}
.tree_view li.tf-dotted-children li:before {
	border-top-style: dotted;
}
.tree_view li.tf-dotted-children > .tree_view_parent:before,
.tree_view li.tf-dotted-children > .tf-node-content:before {
	border-left-style: solid;
}
.tree_view li.tf-dashed-children .tree_view_parent:after,
.tree_view li.tf-dashed-children .tree_view_parent:before,
.tree_view li.tf-dashed-children .tf-node-content:after,
.tree_view li.tf-dashed-children .tf-node-content:before {
	border-left-style: dashed;
}
.tree_view li.tf-dashed-children li:before {
	border-top-style: dashed;
}
.tree_view li.tf-dashed-children > .tree_view_parent:before,
.tree_view li.tf-dashed-children > .tf-node-content:before {
	border-left-style: solid;
}


