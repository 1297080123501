@media (min-width: 320px) and (max-width: 576px) {
  .logomain {
    width: 100px !important;
    height: 100px !important;
  }
  .userimage {
    width: 100px !important;
    height: 100px !important;
  }
  .textmain1 {
    font-size: 25px !important;
  }
  .text2main {
    font-size: 17px !important;
  }
  .textmainmail {
    font-size: 14px !important;
    letter-spacing: 1px !important;
  }
  .addres {
    font-size: 14px !important;
    line-height: 28px !important;
    letter-spacing: 0px !important;
  }
  .name {
    font-size: 25px !important;
  }
  .textmain2 {
    font-size: 17px !important;
  }
  .lattext {
    font-size: 14px !important;
  }
}
