.custom_card {
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 9px 6px #0c0e27;
  height: 165px;
  margin-bottom: 30px;
  padding: 15px;
}

.css-1wxaqej {
  box-sizing: border-box;
  margin: 0px;
  flex-direction: row;
}

.css-1xhj18k {
  display: flex;
  flex-direction: row;
}

.css-1qw96cp {
  padding: 16px;
}

.css-u30lmt {
  margin: 0px;
  /* font-family: Roboto, Helvetica, Arial, sans-serif; */
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: rgba(0, 0, 0, 0.6);
}

.card_info {
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 6px 3px #000;
  height: 130px;
  margin-bottom: 30px;
  padding: 0;
}

.card_info .card_header {
  align-items: center;
  border-bottom: 1px solid #ffa117;
  line-height: 30px;
  padding: 10px 25px;
}

.card_info .card_header h4 {
  color: #000;
  font-size: 17px;
  line-height: 28px;
  margin-bottom: 0;
}

.card_info .card_footer {
  padding: 20px 25px 35px;
}

.card_info .card_footer h2 {
  color: #000;
  font-size: 16px;
  margin-bottom: 12px;
}
